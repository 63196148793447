<template>
  <div class="w-100 mb-3" style="position: relative;">
    <span class="input-group dropdown-toggle"
          ref="dropdownToggle"
          :data-bs-toggle="(disabled ?'': 'dropdown')"
          @click.prevent="searchFocus" >

      <input ref="searchInput" class="form-control sc-search-input"
             type="search" role="textbox" placeholder="Search..."
             autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
             @keypress.enter.prevent="selectHighlightedItem"
             @keyup.up.prevent="highlightPrevItem"
             @keyup.down.prevent="highlightNextItem"
             @blur="resetSearch"
             v-model="searchValue" />
    </span>

    <div class="sc-dropdown-menu dropdown-menu dropdown-menu-end py-0"
         ref="dropdownMenu">
      <ul class="list-group sc-ul"
          :style="{maxHeight: maxHeightClass + 'px'}">
          <li v-if="searchedItems.length === 0" class="text-muted p-2"><em>No results found</em></li>

          <li v-for="(item, idx) in searchedItems"
              :key="'swsag'+idx"
              :ref="'swsag'+idx"
              :class="[(!item.id ? 'px-3 pt-3 pb-2 item-label' : 'item'),
                      (idx === highlightedItemIdx ? 'sc-highlight' : ''),
                      (idx === selectedItemId ? 'selected' : '')]"
              @mouseover="mouseOver((!item.id && item !== reset) ? null : idx)"
              @click="selectItem((item === reset) ? null : item)">
            <span v-if="item === reset">{{ reset }}</span>
            <strong v-else-if="!item.id">{{ item }}</strong>
            <span v-else>{{ item.showName }}</span>
          </li>
        </ul>
      </div>
  </div>
</template>


<script>

  import _ from 'underscore'; // isObject
  import {Dropdown} from 'bootstrap';

  export default {
    name: 'ScSelectWithSearchAndGroups',
    emits: ['sc-select-item'],
    props: {
      items: {type: Array, default: () => []},
      labels: {type: Array, default: () => []},
      placeholder: {type: String, default: 'Select an item...'},
      disabled: {type: Boolean, default: false},
      emptyAfterSelection: {type: Boolean, default: false},
      reset: {type: String, default: null},
      // needed to improve height on user group list
      maxHeightClass: {type: Number, default: 200}
    },
    data () {
      return {
        searchValue: null,
        selectedItemId: null,
        selectedListItemId: null,
        highlightedItemIdx: 0
      }
    },
    mounted () {
      //console.log('mounted');
      this.searchValue = this.displayText;
    },
    methods: {
      selectItem (item) {
        //console.log('selectItem', item, this.selectedItemId);
        if (!this.emptyAfterSelection) {
          if (item === null || !item.id) {
            this.selectedItemId = null;
            this.selectedListItemId = null;
          } else {
            this.selectedItemId = item.id;
            this.selectedListItemId = item.list_id;
          }
        }
        this.highlightedItemIdx = 0;
        this.searchValue = this.displayText;
        this.checkDdToggle('true');
        if (item === null || !item.id) this.$emit('sc-select-item', null);
        else this.$emit('sc-select-item', item);
      },
      searchFocus () {
        if (this.disabled) return;
        //console.log('searchFocus', this.$refs.searchInput);
        this.highlightedItemIdx = 0;
        this.searchValue =  null;
        setTimeout(() => {
          if (this.$refs.searchInput) {
            this.$refs.searchInput.focus();
            this.$refs.searchInput.select();
            this.checkDdToggle('false');
          }
        }, 150);
      },
      resetSearch () {
        //console.log('blur', this.selectedItemId, this.highlightedItemIdx)
        // need to wait if click event on dropdown gets fired and sets selectedItemId
        // need to wait if selectHighlightedItem is fired otherwise this overrides searchValue
        setTimeout(() => {
          this.scrollInView(); // scroll list back up
          if (!this.highlightedItemIdx) {
            this.searchValue = this.displayText;
          } else {
              this.searchValue = this.displayText;
          }
        }, 300)
      },
      checkDdToggle (ariaExpandedCheck) {
        let dd = Dropdown.getInstance(this.$refs.dropdownToggle);
        if (dd && dd._element.ariaExpanded === ariaExpandedCheck) dd.toggle();
      },
      scrollInView () {
        let container = this.$refs.dropdownMenu;
        if (!container) return;
        let cHeight = container.clientHeight;
        let cTop = container.scrollTop;
        let element = this.$refs['swsag' + this.highlightedItemIdx];
        if (!element)
          return;
        let eTop = element.offsetTop - container.offsetTop;
        let eBottom = eTop + element.clientHeight;
        if ((eTop <= 0 && eBottom > 0 ) || (eTop >= 0 && eTop <= cHeight) || (eTop >= 0 && eTop >= cHeight) || (eTop === cTop))
          element.scrollIntoView({block: 'nearest'});
      },

      selectHighlightedItem () {
        //console.log('selectHighlightedItem', this.highlightedItemIdx, this.searchedItems);
        if (this.highlightedItemIdx < 0) return;
        if (!this.searchedItems[this.highlightedItemIdx]) return;
        this.selectItem(this.searchedItems[this.highlightedItemIdx]);
        if (this.$refs.searchInput) {
            this.$refs.searchInput.blur();
        }
        this.checkDdToggle('true')
      },
      highlightPrevItem () {
        //console.log('highlightPrevItem');
        this.highlightedItemIdx = this.highlightedItemIdx - 1;
        if (this.highlightedItemIdx < 0)
          this.highlightedItemIdx = 0;
        if (this.highlightedItemIdx !== 0 && !_.isObject(this.searchedItems[this.highlightedItemIdx]))
          this.highlightedItemIdx = this.highlightedItemIdx - 1;
        this.scrollInView();
      },
      highlightNextItem () {
        //console.log('highlightNexItem');
        this.highlightedItemIdx = this.highlightedItemIdx + 1;
        if (this.highlightedItemIdx >= this.searchedItems.length)
          this.highlightedItemIdx = this.searchedItems.length-1;
        if (this.highlightedItemIdx !== 0 && !_.isObject(this.searchedItems[this.highlightedItemIdx]))
          this.highlightedItemIdx = this.highlightedItemIdx + 1;
        this.scrollInView();
      },
      mouseOver (idx) {
        if (idx || idx === 0) this.highlightedItemIdx = idx;
      }
    },
    computed: {
      prepItems () {
        let arr = [];
        if (this.reset)
          arr.push(this.reset);
        let listId = 0;
        for (const [idx, group] of this.items.entries()) {
          if (this.labels && this.labels[idx])
            arr.push(this.labels[idx]);
          for (let item of group) {
            item.list_id = listId;
            arr.push(item);
            listId++;
          }
        }
        //console.log('prepItems', arr);
        return arr;
      },
      searchedItems () {
        //console.log('searchedItems', this.searchValue);
        let filtered =  this.prepItems.filter((item) => {
          if (!this.searchValue || (this.selectedItem && this.searchValue === this.selectedItem.showName)) return true;
          if (item === this.reset) return false;
          if (!item.showName) return item;
          return item.showName.toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) !== -1;
        });
        //console.log('searchedItems', filtered);
        return filtered;
      },
      selectedItem () {
        //console.log('selectedItem', this.selectedItemId);
        if (!this.selectedItemId) return null;
        return this.prepItems.find(t => t.list_id === this.selectedListItemId && t.id === this.selectedItemId);
      },
      displayText () {
        //console.log('displayText', this.reset, this.placeholder);
        if (this.selectedItem) return this.selectedItem.showName;
        if (!this.selectedItem) return this.placeholder;
        return this.reset;
      }
    },
  }
</script>


<style scoped>

  .sc-dropdown-menu {
    width: 100%;
    overflow-y: scroll;
    cursor: pointer;
    padding: 0;
  }
  .dropdown-toggle::after {  /* make sure toggle is ALWAYS positioned on right edge */
    right: 15px;
    top: 21px;
    position: absolute;
  }
  .dropdown-toggle:hover {
    cursor: pointer;
  }
  .sc-ul li.sc-highlight {
    background-color: #f3f3f9;
  }
  .sc-ul .item {
    padding: 0.5625rem 1.125rem;
  }
  .sc-ul .item-label {
    cursor: default;
  }

  /* hide browser search clear x */
  input[type="search"]::-webkit-search-cancel-button {
    display: none;
  }

</style>
